<template>
  <v-card raised elevation="6" class="mt-1">
    <v-card-title>
      <h4
        style="
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 100;
          text-decoration: underline;
        "
      >
        {{ $t("sales.byPeriod") }}
      </h4>
      <v-spacer></v-spacer>
      <v-btn @click="graphic = !graphic" :outlined="graphic" height="30px"
        >Tabla</v-btn
      >
    </v-card-title>
    <v-card-text v-if="graphic"> <LineChartPeriods /> </v-card-text>
    <v-data-table
      v-else
      :headers="headersByPeriod"
      :items="list"
      :items-per-page="5"
      id="salesByService-table"
    >
      <template v-slot:item.data="{ item }">
        {{ item.data }}
      </template>
      <template v-slot:item.numVentas="{ item }">
        {{ item.numVentas }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "SalesByPeriod",
  components: {
    LineChartPeriods: () =>
      import("@/components/stats/sales/graphics/SalesByPeriodGraphic"),
  },
  ...mapState("stats", ["lists"]),
  computed: {
    //METODO / VARIABLE
    list() {
      return this.$store.state.stats.lists.salesByPeriod;
    },
  },
  data() {
    return {
      graphic: true,
      headersByPeriod: [
        {
          text: this.$t("date"),
          align: "center",
          sortable: false,
          value: "data",
        },

        {
          text: this.$t("quantity"),
          align: "center",
          sortable: false,
          value: "numVentas",
        },
      ],
    };
  },
};
</script>

<style></style>
